import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlatformService } from './platform.service';

@Injectable({
    providedIn: 'root',
})
export class VisibilityWatcherService {

    constructor(
        private _platformService: PlatformService
    ) {

    }

    watch(
        element: HTMLElement,
    ): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            let intersectionObserver: IntersectionObserver | undefined;
            if (this._platformService.isBrowser()) {
                intersectionObserver = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        observer.next(entry.isIntersecting);
                    });
                });
    
                intersectionObserver.observe(element);
    
            }
            return () => {
                intersectionObserver?.unobserve(element);
                intersectionObserver?.disconnect();
            };
        });
    }
}
