import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface EmailUnsubscribeDTO {
    email: string;
    messageStreamId?: string;
    listId?: any;
    scope?: any;
    e?: string; // optional email to cover edge cases of delayed webhooks
}

@Injectable()
export class EmailSubscriptionService {

    private apiUrl = `${environment.baseUrl}/api/emails/subscriptions`;

    constructor(private http: HttpClient) { }

    unsubscribeFromStream(dto: EmailUnsubscribeDTO): Observable<void> {
        const url = `${this.apiUrl}/unsubscribe`;
        return this.http.post<void>(url, dto);
    }

    resubscribeFromStream(dto: EmailUnsubscribeDTO): Observable<void> {
        const url = `${this.apiUrl}/subscribe`;
        return this.http.put<void>(url, dto);
    }

    subscribeToOffer(email: string, slugs: string[]): Observable<void> {
        const url = `${this.apiUrl}/offers/ny`;
        return this.http.post<void>(url, { email, slugs})
    }
}
