import { Component, OnInit } from '@angular/core';
import { EmailSubscriptionService, EmailUnsubscribeDTO } from '../../services/email-subscriptions.service';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';
import { TitleService } from 'src/app/services/title.service';

const enum StreamId {
    CrewfictionNotification = "crewfiction-notifications",
    Outbound = "outbound",
    SdAuthor = "sd-author",
    StoryDive = "storydive"
}

@Component({
    selector: 'unsub',
    templateUrl: './unsub.component.html',
    styleUrls: ['./unsub.component.scss']
})
export class UnsubComponent implements OnInit {
    
    loading: boolean = true;
    message: string = "Unsubscribing..."
    status: "subscribed" | "unsubscribed" = "subscribed";

    streamMap: {[key in StreamId]: string} = {
        "crewfiction-notifications": "crewfiction notifications",
        "outbound": "product notifications",
        "sd-author": "this type of notifications",
        "storydive": "Storydive"
    };

    unsubToken!: string;
    messageStreamId!: string;
    listId?: string;
    tagId?: string;
    e?: string;

    constructor(
        private readonly _emailSubscriptionsService: EmailSubscriptionService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _platformService: PlatformService,
        private readonly _titleService: TitleService
    ) { }

    async ngOnInit() {
        if (this._platformService.isBrowser()) {
            this.unsubToken = this._activatedRoute.snapshot.queryParams.s;
            this.messageStreamId = this._activatedRoute.snapshot.params.streamId;
            this.e = this._activatedRoute.snapshot.queryParams.e;
            this.keepTitle(this.messageStreamId == "storydive" ? "Storydive" : "Unsubscribe");
            this.listId = this._activatedRoute.snapshot.queryParams.l;
            this.tagId = this._activatedRoute.snapshot.queryParams.t;
            await this.unsub();
        }
        else {
            this._titleService.setTitle("Unsubscribe", false);
        }
    }
    
    private keepTitle(t: string) {
        this.setTitle(t);
        setInterval(() => {
            this.setTitle(t);
        }, 500);
    }

    setTitle(t: string) {
        this._titleService.setTitle(t, false);
    }

    async unsub() {
        this.loading = true;
        const dto: EmailUnsubscribeDTO = { 
            email: this.unsubToken,
            messageStreamId: this.messageStreamId,
        };
        if (this.e) {
            dto.e = this.e;
        }
        if (this.listId) {
            dto.listId = this.listId;
        }
        if (this.tagId) {
            dto.scope = this.tagId;
        }
        await firstValueFrom(this._emailSubscriptionsService.unsubscribeFromStream(dto));
        this.message = "You have been successfully unsubscribed from " + this.getFinalMessageByStreamId(this.messageStreamId as StreamId);
        this.status = "unsubscribed";
        this.loading = false;
    }

    async resub() {
        this.loading = true;
        const dto: EmailUnsubscribeDTO = { 
            email: this.unsubToken,
            messageStreamId: this.messageStreamId ,
        };
        if (this.listId) {
            dto.listId = this.listId;
        }
        if (this.tagId) {
            dto.scope = this.tagId;
        }
        await firstValueFrom(this._emailSubscriptionsService.resubscribeFromStream(dto));
        this.message = "You have been successfully subscribed to " + this.getFinalMessageByStreamId(this.messageStreamId as StreamId);
        this.status = "subscribed";
        this.loading = false;
    }

    private getFinalMessageByStreamId(streamId: StreamId): string {
        let msg = this.streamMap[streamId] as string;
        if (!msg) {
            msg = "Notifications";
        }
        return msg;
    }
}
