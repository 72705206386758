<div class="row c-mt-60" *ngIf="!loading">
    <div class="col-12 d-flex flex-column align-items-center">
        <h3 class="c-mb-60">{{message}}</h3>
        <button class="btn btn-round btn-primary" *ngIf="status == 'subscribed'" (click)="unsub()">
            Unsubscribe
        </button>
        <ng-container *ngIf="status == 'unsubscribed'">
            <p>
                Was this a mistake?
            </p>
            <button class="btn btn-round btn-primary" (click)="resub()">
                Re-Subscribe
            </button>
        </ng-container>
    </div>
</div>

<!-- <ng-container *ngIf="loading">
    <p>
        Loading...
    </p>
</ng-container> -->