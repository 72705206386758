<ng-container *ngIf="!disableClick">
    <div class="badge-collection" [ngClass]="{'horizontal': horizontalMode}">
        <ng-container *ngFor="let item of list">
            <ng-container *ngIf="item.image">
                <span class="badge-with-image" [routerLink]="item.url" (click)="badgeClick(item)">
                    <a [routerLink]="item.url" class="{{item.type | pillType}} d-flex align-items-center" (click)="badgeClick(item)">
                        <img
                            [src]="item.image | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                            [alt]="item.text"
                            class="circular-pfp c-me-8"
                        />
                        <span>{{ item.text }}</span>
                    </a>
                </span>
            </ng-container>
            <ng-container *ngIf="!item.image">
                <span class="badge" [routerLink]="item.url" (click)="badgeClick(item)">
                    <a [routerLink]="item.url" class="{{item.type | pillType}}" (click)="badgeClick(item)">
                        {{item.text}}
                    </a>
                </span>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="disableClick">
    <div class="badge-collection" [ngClass]="{'horizontal': horizontalMode}">
        <ng-container *ngFor="let item of list">
            <ng-container *ngIf="item.image">
                <span class="inactive-badge-with-image">
                    <img
                        [src]="item.image | cloudinaryScaleHeight : 22 | cloudinaryQuality : 80 | cloudinaryUrl"
                        [alt]="item.text"
                        class="circular-pfp c-me-8"
                    />
                    <span>{{item.text}}</span>
                </span>
            </ng-container>
            <ng-container *ngIf="!item.image">
                <span class="badge inactive-badge">
                    {{item.text}}
                </span>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<div class="c-pt-10 badge-collection" *ngIf="expandable && !expanded && data.length > showInitial">
    <button class="btn btn-round btn-secondary w-fit badge-view-more-btn d-flex align-items-center" (click)="expand()">
        {{expandCta}}
        <feather-icon icon="chevron-down" class="c-ms-5"></feather-icon>
    </button>
</div>