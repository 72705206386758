import { Component, Input, OnInit, HostListener, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import emailSpellChecker from '@zootools/email-spell-checker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Book } from 'src/app/models/book.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PlatformService } from 'src/app/services/platform.service';
import { firstValueFrom, combineLatest, filter, Subscription } from 'rxjs';
import { EmbeddedAuthService } from 'src/app/modules/auth/services/embedded-auth.service';
import { FollowService } from 'src/app/modules/books/services/follow.service';
import { LibraryStateService } from 'src/app/state/library.state.service';
import { AnonymousPersistentState } from 'src/app/services/anonymous-persistent-state';
import { Readable } from 'src/app/models/readable.model';
import { ReadablesService } from 'src/app/modules/readables/services/readables.service';
import { FollowersService } from 'src/app/services/followers.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { CanonicalService } from 'src/app/services/canonical.service';
import { environment } from 'src/environments/environment';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';

@Component({
    selector: 'worldmaker-component-free-book-cover-header',
    templateUrl: './free-book-cover-header.component.html',
    styleUrls: ['./free-book-cover-header.component.scss'],
    animations: [
        trigger(
          'stickyFooterAnimation', 
          [
            transition(
              ':enter', 
              [
                style({ "max-height": 0 }),
                animate('1s ease-out', style({ "max-height": "250px" }))
              ]
            ),
            transition(
              ':leave', 
              [
                style({ "max-height": "250px" }),
                animate('1s ease-in', style({ "max-height": 0 }))
              ]
            )
          ]
        ),
        trigger(
            'errorMessageAnimation', 
            [
                transition(
                    ':enter',
                    [
                        style({ "opacity": 0 }),
                        animate('0.5s ease-out', style({ "opacity": 1 }))
                    ]
                )
            ]
        )
    ]
})
export class FreeBookCoverHeader implements OnInit, OnDestroy {
    @Input() data!: {
        book: Book;
        joinCrewImage?: {
            channel: 'image' | 'manual_image';
            data: { url: string; description: string, category: string };
        };
        recommendedBooks: Book[];
        ctaText: string;
        headerText: string;
        showHeader: boolean;
        enableStickyFooter: boolean;
        enableBreadcrumbs: boolean;
        showRating: boolean;
        showSpicyMeter: boolean;
        acValuePostfix?: string;
        altCoverIndex?: string;
        altReadableSlug?: string;
    };
    book!: Book;
    readable!: Readable;
    authChecked$ = this._authService.browserAuthCheckPassed$;

    loading = false;
    scrolled = false;
    emailSuggestionError = "";
    emailForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });

    isLoggedIn = false;
    followsAuthor = false;
    hasFreeBook = false;

    subs: Subscription[] = [];

    followRequestInProgress$ = this._route.queryParams.pipe(
        filter(params => params["action"] === "follow_author" && params["author"] === this.mainAuthor.slug),
        filter(() => this._platformService.isBrowser())
    );

    constructor(
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _platformService: PlatformService,
        private readonly _analyticsService: AnalyticsService,
        private readonly _authService: EmbeddedAuthService,
        private readonly _followService: FollowService,
        private readonly _libraryStateService: LibraryStateService,
        private readonly _anonymousPersistentState: AnonymousPersistentState,
        private readonly _readablesService: ReadablesService,
        private readonly _followersService: FollowersService,
        private readonly _utilitiesService: UtilitiesService,
        private readonly _canonicalService: CanonicalService,
        private readonly _chatWidgetService: ChatWidgetService
    ) {}

    async ngOnInit() {
        let email = this._anonymousPersistentState.email;
        email = (await firstValueFrom(this._route.queryParamMap)).get("email") || email;
        if (this._authService.user) {
            email = this._authService.user.email;
        }
        if (email) {
            this.emailFormControl.setValue(email);
        }
        this.book = this.data.book;
        if (!this._router.url.includes("/pages")) {
            this._canonicalService.set(`${environment.baseUrl}${this._utilitiesService.getBookUrl(this.book)}`);
        }
        this._followService.followsAuthor$.subscribe(val => {
            this.followsAuthor = val;
        });
        await this._followService.init(
            this.book,
            this.mainAuthor,
            null
        );
        this._analyticsService.trackPageView(undefined, this._router.url.match("/pages/") ? "Landing Page" : "Book Page", {
            book: this.book.slug,
            author: this.book.mainAuthorSlug,
            mode: "free",
            publisher: this.book.publisher || undefined
        });
        if (this._anonymousPersistentState.freeBooks.find(b => b === this.book.slug)) {
            this.hasFreeBook = true;
            //go to the actual book page if they have the book
            if (this._router.url.match("/pages/")) {
                return this._router.navigate([this._utilitiesService.getBookUrl(this.book)]);
            }
        }
        this._authService.isLoggedIn$.subscribe((status) => {
            this.isLoggedIn = status;
        });
        this.followRequestInProgress$.subscribe(() => {
            this.followAuthor();
        });
        //load/save free books
        const sub = combineLatest([this._authService.isLoggedIn$, this._libraryStateService.freeShelf$]).pipe(
            filter(([isLoggedIn, shelf]) => isLoggedIn && shelf !== null)
        ).subscribe(async ([_isLoggedIn, shelf]) => {
            for (const bookSlug of this._anonymousPersistentState.freeBooks) {
                if (!shelf!.books.find(b => b.slug === bookSlug)) {
                    const readable = await firstValueFrom(this._readablesService.infoByBookSlug(this.data.altReadableSlug ? this.data.altReadableSlug : bookSlug, "free_book"));
                    if (!readable || !readable.book || !readable.book.isFree) continue;
                    //follow the author
                    if (readable.author) {
                        if (readable.author.id === this.mainAuthor.id) {
                            await this._followService.followAuthor({source: "auto"});
                        } else {
                            await this._followersService.followAuthor(readable.author.id!, {source: "auto"});
                        }
                    }
                    //save the book
                    this._libraryStateService.addBookToFreeShelf(readable.book!.id!, {
                        altCoverIndex: this.data.altCoverIndex && Number(this.data.altCoverIndex) >= 0 ? Number(this.data.altCoverIndex) : undefined,
                        altReadableSlug: this.data.altReadableSlug
                    });
                }
            }
            if (!this._anonymousPersistentState.freeBooks.includes(this.data.book.slug)) {
                //TODO this bugs in rare cases and shelf.books is empty (even though you have books)
                if (shelf!.books.find(b => b.id === this.data.book.id)) {
                    this.hasFreeBook = true;
                    //go to the actual book page if they have the book
                    if (this._router.url.match("/pages/")) {
                        await this._router.navigate([this._utilitiesService.getBookUrl(this.book)]);
                    }
                } else {
                    this.hasFreeBook = false;
                }
            }
        });
        this.subs.push(sub);
        //get the readable for reading
        const fb = await firstValueFrom(this._readablesService.infoByBookSlug(this.book.slug, "free_book"));
        if (fb) {
            this.readable = fb;
        }
        return;
    }

    ngOnDestroy(): void {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
    }

    @HostListener("window:scroll")
    async onWindowScroll() {
        if (!this.data.enableStickyFooter) return;
        const headerHeight = parseInt(getComputedStyle(document.querySelector(".book-header-container")!).height.replace("px", ""));
        if (window.scrollY >= headerHeight) {
            this.scrolled = true;
            const sticky = document.querySelector(".sticky-container");
            if (sticky) {
                //add some default margin before the actual one we get after the animation
                if (await firstValueFrom(this._platformService.isMobile)) {
                    document.body.style.marginBottom = "228px";
                } else {
                    document.body.style.marginBottom = "93px";
                }
                setTimeout(() => {
                    const stickyHeight = getComputedStyle(sticky).height;
                    document.body.style.marginBottom = stickyHeight;
                    const stickyHeightNumber = parseInt(stickyHeight.replace("px", ""));
                    this._chatWidgetService.slideUp(stickyHeightNumber + 10);
                }, 1200);
            }
        } else {
            this.scrolled = false;
            setTimeout(() => {
                this._chatWidgetService.resetPosition();
            }, 500);
        }
    }

    get mainAuthor() {
        const main = this.book.authors.find(
            (author) => author.slug === this.book.mainAuthorSlug
        );
        if (main) {
            return main;
        } else {
            return this.book.authors[0];
        }
    }

    get coverSrc(): string {
        try {
            let src;
            if (this.data.altCoverIndex !== undefined && Number(this.data.altCoverIndex) >= 0 && this.book.images.altCovers?.length) {
                src = this.book.images.altCovers[Number(this.data.altCoverIndex)];
            }
            else {
                src = this.book.images.cover;
            }
            //only overwrite it on normal book pages, not landing pages
            if (!this._router.url.match("/pages/")) {
                this._platformService.metaTags.setOpenGraphImage(src);
            }
            return src;
        }
        catch(e) {
            console.warn("Error in book cover source", e);
            return this.book.images.cover;
        }
    }

    get isMobile() {
        return this._platformService.isMobile;
    }

    async readNow() {
        if (!this.emailForm.valid) return;
        const email = this.emailFormControl.value;

        this._anonymousPersistentState.saveEmail(email);

        let acValue = `${this.book.mainAuthorSlug}--${this.book.slug}`; //activeCampaign encode
        //some extra info to know when it's from the landing page or the book page
        if (this._router.url.match("/pages/")) {
            acValue += "--pages";
            if (this.data.acValuePostfix) {
                acValue += `${this.data.acValuePostfix}`;
            }
        }

        this.loading = true;
        await this._analyticsService.identifyAndTrack({email, traits: {signupReason: {type: "free_book_flow", book: this.book.slug}}}, {
            event: "get_free_book",
            params: {book: this.book, acValue, type: "free_book", placement: "book_page"}
        });
        this.loading = false;

        this._analyticsService.trackClientOnly({event: "get_free_book_c", params: {book: this.book, acValue, type: "free_book", placement: "book_page"}});
    
        //start flow
        this._anonymousPersistentState.saveFreeBook(this.book.slug, {
            altCoverIndex: this.data.altCoverIndex && Number(this.data.altCoverIndex) >= 0 ? Number(this.data.altCoverIndex) : undefined,
            altReadableSlug: this.data.altReadableSlug
        });
        if (this.isLoggedIn) {
            await this._followService.followAuthor({source: "book_page", book: this.book.slug});
            if (!this.hasFreeBook) {
                this._libraryStateService.addBookToFreeShelf(this.data.book.id!, {
                    altCoverIndex: this.data.altCoverIndex && Number(this.data.altCoverIndex) >= 0 ? Number(this.data.altCoverIndex) : undefined,
                    altReadableSlug: this.data.altReadableSlug
                });
            }
        }
        await this._router.navigate([`/authors/${this.mainAuthor.slug}/recommends`], {
            state: {
                recommendedBooks: this.data.recommendedBooks || [],
                freeBook: this.data.book
            }
        });
        this.hasFreeBook = true;

        //restore chat
        this._chatWidgetService.resetPosition();
    }

    get emailFormControl(): FormControl {
        return this.emailForm.get('email') as FormControl;
    }

    get emailErrors(): string[] {
        const errors = this.emailFormControl.errors;
        if (!errors) return [];
        return Object.keys(errors).map(key => {
            switch (key) {
                case 'required': return "Email is required";
                case 'email': return "You must enter a valid email address";
                default: return "Unknown error";
            }
        });
    }

    onEmailChanged() {
        if (!this.emailForm.valid) {
            this.emailSuggestionError = "";
            return;
        }
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            this.emailSuggestionError = suggestion.full;
        } else {
            this.emailSuggestionError = "";
        }
    }

    onStickyInputClicked(event: Event) {
        setTimeout(() => {
            this._utilitiesService.scrollStickyContainerVisible(event.target as HTMLElement);
        }, 500);
    }

    correctEmail() {
        const suggestion = emailSpellChecker.run({
            email: this.emailFormControl.value
        });
        if (suggestion) {
            //track it
            this._analyticsService.track({event: "email_typo_correction", params: {old: this.emailFormControl.value.split("@")[1], new: suggestion.full.split("@")[1]}});
            this.emailFormControl.setValue(suggestion.full);
            this.emailSuggestionError = "";

        }
    }

    async followAuthor() {
        await this._followService.followAuthor({source: "book_page", book: this.book.slug});
    }

    async unfollowAuthor() {
        await this._followService.unfollowAuthor();
    }
}
