@if (dataSource && dataSource.data && dataSource.data.length) {
    @if (horizontallyScollable && horizontalChildWidth) {
        <div class="row horizontal-collection" (wheel)="scrollAxisReverse($event)" #horizontalScroller>
            <div [style]="'width:' + horizontalChildWidth + 'px;'" *ngFor="let item of dataSource.data">
                <content-card [template]="template"
                              [title]="getTitle(item)"
                              [subtitle]="getSubtitle(item)"
                              [image]="getImage(item)"
                              [badge]="getBadge(item)"
                              [url]="getUrl(item)"
                              [onClick]="getOnClick(item)"
                              [externalLink]="externalLink"
                              [hideSubtitle]="hideSubtitle"
                              [lazy]="getLazy(item)"
                              [bottomBadge]="getBottomBadge(item)">
                </content-card>
            </div>
        </div>
    } @else if (horizontallyScollable) {
        <div class="row horizontal-collection no-responsive" (wheel)="scrollAxisReverse($event)" #horizontalScroller>
            <div *ngFor="let item of dataSource.data">
                <content-card [template]="template"
                              [title]="getTitle(item)"
                              [subtitle]="getSubtitle(item)"
                              [image]="getImage(item)"
                              [badge]="getBadge(item)"
                              [url]="getUrl(item)"
                              [onClick]="getOnClick(item)"
                              [externalLink]="externalLink"
                              [hideSubtitle]="hideSubtitle"
                              [lazy]="getLazy(item)"
                              [bottomBadge]="getBottomBadge(item)">
                </content-card>
            </div>
        </div>
    } @else if (!horizontallyScollable) {
        <div class="row vertical-collection">
            <div class="col-{{12 / columnLayout.mobile}} col-sm-{{12 / columnLayout.sm}} col-md-{{12 / columnLayout.md}} col-lg-{{12 / columnLayout.lg}}" *ngFor="let item of dataSource.data">
                <content-card [template]="template"
                              [title]="getTitle(item)"
                              [subtitle]="getSubtitle(item)"
                              [image]="getImage(item)"
                              [badge]="getBadge(item)"
                              [url]="getUrl(item)"
                              [onClick]="getOnClick(item)"
                              [externalLink]="externalLink"
                              [hideSubtitle]="hideSubtitle"
                              [lazy]="getLazy(item)"
                              [bottomBadge]="getBottomBadge(item)">
                </content-card>
            </div>
        </div>
    }
}
