import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewChecked, ChangeDetectionStrategy, Component, computed, ElementRef, input, Input, InputSignal, OnChanges, OnInit, Signal, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom, map, Observable, shareReplay, switchMap } from 'rxjs';
import { Author, CustomImageAppearance, FlatColorAppearance, GradientAppearance, ThemeAppearance, VideoThemeAppearance } from 'src/app/models/author.model';
import { BadgeConfig } from 'src/app/models/badge-config.model';
import { BookSeries } from 'src/app/models/book-series.model';
import { Book, BookReview } from 'src/app/models/book.model';
import { ColumnLayoutConfig } from 'src/app/models/column-layout-config.model';
import { Genre } from 'src/app/models/genre.model';
import { Trope } from 'src/app/models/trope.model';
import { ContentGridDataSource } from 'src/app/modules/shared/components/content-grid/content-grid.component';
import { CloudinaryService } from 'src/app/services/cloudinary.service';
import { PlatformService } from 'src/app/services/platform.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { VisibilityWatcherService } from 'src/app/services/visibility-watcher.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'author-landing-page',
    templateUrl: './author-landing-page.component.html',
    styleUrl: './author-landing-page.component.scss',
    animations: [
        trigger(
            'stickyFooterAnimation',
            [
                transition(
                    ':enter', 
                    [
                      style({ "max-height": 0 }),
                      animate('1s ease-out', style({ "max-height": "160px" }))
                    ]
                  ),
                transition(
                    ':leave', 
                    [
                      style({ "max-height": "160px" }),
                      animate('1s ease-in', style({ "max-height": 0 }))
                    ]
                )
            ]
        )
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorLandingPageComponent implements OnInit, OnChanges, AfterViewChecked {

    @Input()
    previewMode: boolean = false;

    @Input()
    author: Author | null = null;
    private author$ = new BehaviorSubject<Author | null>(null);

    @Input()
    featuredBook: Book | null = null;

    @Input()
    featuredBooks: Book[] | null = [];
    private featuredBooks$ = new BehaviorSubject<Book[]>([]);
    featuredBooksDataSource$: Observable<ContentGridDataSource<Book> | null> = this.featuredBooks$.asObservable().pipe(
        map(books => {
            if (books.length === 0) return null;
            return {
                data: books,
                title: b => b.title,
                subtitle: b => "",
                image: b => b.images.cover,
                url: this.previewMode ? null : b => environment.baseUrl + this._utilitiesService.getBookUrl(b) + this.utmQueryString,
                bottomBadge: b => b.releaseBadge
            } as ContentGridDataSource<Book>;
        }),
        shareReplay(1)
    )

    readerMagnetMode: "simple_form" | "free_book" | null = null;
    simpleForm?: {
        buttonText: string;
        description: string;
        placeholderText: string;
    };
    freeBookForm? : {
        buttonText: string;
        description: string,
        placeholderText: string
    }
    formData: {
        recommendedBooks?: Book[],
        altCoverIndex?: string,
        altReadableSlug?: string,
        acValuePostfix?: string,
        ctaText: string
        placeholder?: string
    } = {
        ctaText: "Subscribe",
        placeholder: "Enter your email"
    }

    @Input()
    authorSeries: BookSeries[] | null = [];
    private authorSeries$ = new BehaviorSubject<BookSeries[]>([]);
    authorSeriesDataSource$: Observable<ContentGridDataSource<BookSeries> | null> = this.authorSeries$.asObservable().pipe(
        map(series => {
            if (series.length === 0) return null;
            return {
                data: series,
                title: s => s.title,
                subtitle: s => "",
                image: s => s.images.cover,
                url: s => this.previewMode ? null : environment.baseUrl + this._utilitiesService.getBookSeriesUrl(s) + this.utmQueryString,
            } as ContentGridDataSource<BookSeries>;
        }),
        shareReplay(1)
    );

    @Input()
    featuredReviews: BookReview[] | null = [];

    genres: InputSignal<Genre[] | null> = input<Genre[] | null>(null);
    tropes: InputSignal<Trope[] | null> = input<Trope[] | null>(null);

    genresConfig: Signal<BadgeConfig[]> = computed(() => {
        const genres = this.genres();
        if (!genres) return [];
        return genres.map(genre => {
            return {
                text: genre.name,
                type: "default",
                url: environment.baseUrl + this._utilitiesService.getGenreUrl(genre.slug) + this.utmQueryString, //TODO broken
                externalLink: true
            };
        }) as BadgeConfig[];
    })

    tropesConfig: Signal<BadgeConfig[]> = computed(() => {
        const tropes = this.tropes();
        if (!tropes) return [];
        return tropes.map(trope => {
            return {
                text: trope.name,
                type: "default",
                url: environment.baseUrl + this._utilitiesService.getTropeUrl(trope.slug) + this.utmQueryString, //TODO broken
                externalLink: true
            };
        }) as BadgeConfig[];
    })

    @ViewChild("featuredBookEl")
    featuredBookEl?: ElementRef;
    featureBookEl$ = new BehaviorSubject<ElementRef | null>(null);

    primaryColor: string = "#000";
    utmQueryString = "";
    authorUrl: string = "";

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.featuredBooks?.currentValue) {
            this.featuredBooks$.next(this.featuredBooks!);
        }
        if (changes.authorSeries?.currentValue) {
            this.authorSeries$.next(this.authorSeries!);
        }
        if (changes.author?.currentValue) {
            await this.configBackground();
            this.configReaderMagnet();
            if (!this.previewMode) {
                this.utmQueryString = `?utm_source=author_landing_page&utm_content=${this.author!.slug}`;
                this.authorUrl = environment.baseUrl + this._utilitiesService.getAuthorUrl(this.author!) + this.utmQueryString;
            }
            this.author$.next(this.author);
        }
    }

    backgroundVideo: string = "";
    backgroundImage: string = "";
    backgroundColor: string = "";
    textColor: string = "#fff";
    

    private async configBackground() {
        if (this.author!.landingPageData?.appearance?.kind) {
            const appearance = this.author!.landingPageData.appearance;
            switch(appearance.kind) {
                case "theme":
                    this.backgroundImage = (appearance as ThemeAppearance).theme.imageUrl;
                    this.primaryColor = (appearance as ThemeAppearance).theme.primaryColor;
                    this.textColor = (appearance as ThemeAppearance).theme.textColor;
                    break;
                case "video_theme":
                    this.backgroundVideo = (appearance as VideoThemeAppearance).theme.src;
                    this.primaryColor = (appearance as VideoThemeAppearance).theme.primaryColor;
                    this.textColor = (appearance as VideoThemeAppearance).theme.textColor;
                    this.backgroundImage = "";
                    this.backgroundColor = "";
                    break;
                case "flat_color":
                    this.backgroundColor = (appearance as FlatColorAppearance).color;
                    this.textColor = (appearance as FlatColorAppearance).textColor;
                    break;
                case "gradient": 
                    this.backgroundColor = (appearance as GradientAppearance).color;
                    this.textColor = (appearance as GradientAppearance).textColor;
                    break;
                case "custom_image":
                    this.backgroundImage = (appearance as CustomImageAppearance).url
                    this.textColor = (appearance as CustomImageAppearance).textColor;
                    break;
                default:
                    break;
            }
        }
        else if (this.author!.landingPageData?.background) {
            this.backgroundImage = this.author!.landingPageData?.background.image;
            // if (bgImage) {
            //     this.primaryColor = await firstValueFrom(this._cloudinaryService.getPrimaryColor(
            //         this._cloudinaryService.publicIdSimplified(bgImage)
            //     ));
            // }
        }
    }

    private configReaderMagnet() {
        if (this.author!.landingPageData?.readerMagnet) {
            this.readerMagnetMode = this.author!.landingPageData.readerMagnet.mode;
            if (this.author?.landingPageData.readerMagnet.mode == "simple_form") {
                this.simpleForm = this.author.landingPageData.readerMagnet.simpleForm;
                this.formData.ctaText = this.author.landingPageData.readerMagnet.simpleForm.buttonText;
                this.formData.placeholder = this.author.landingPageData.readerMagnet.simpleForm.placeholderText;
            }
            else if (this.author?.landingPageData.readerMagnet.mode == "free_book") {
                this.freeBookForm = this.author.landingPageData.readerMagnet.freeBookForm;
                this.formData.ctaText = this.freeBookForm.buttonText;
                this.formData.placeholder = this.freeBookForm.placeholderText;
            }
        }

    }

    ngOnInit(): void {
        if (this._platformService.isBrowser()) {
            window.onmessage = (event) => {
                if (event.data?.type === "cfa:scroll") {
                    const targetId = event.data.targetId;
                    const targetEl = document.getElementById(targetId);
                    if (targetEl) {
                        targetEl.scrollIntoView({ behavior: "smooth" });
                    }
                }      
            }
        }
    }

    ngAfterViewChecked() {
        if (this.featuredBookEl) {
            this.featureBookEl$.next(this.featuredBookEl);
        }
    }

    freeBookData = {
        recommendedBooks: [],
        altCoverIndex: "",
        altReadableSlug: "",
        acValuePostfix: "",
        ctaText: "Read for free"
    }

    columnLayout: ColumnLayoutConfig = {
        mobile: 4,
        sm: 4,
        md: 4,
        lg: 4
    };

    scrolled$: Observable<boolean> = this.featureBookEl$.pipe(
        filter(el => !!el),
        distinctUntilChanged(),
        switchMap(el => this._visibilityWatcher.watch(el?.nativeElement)),
        map(visible => !visible),
        shareReplay(1)
    )

    scrollToFeatured() {
        this.featuredBookEl?.nativeElement.scrollIntoView({ behavior: "smooth" });
    }

    constructor(
        private _utilitiesService: UtilitiesService,
        private _cloudinaryService: CloudinaryService,
        private _visibilityWatcher: VisibilityWatcherService,
        private _platformService: PlatformService
    ) { }

}
