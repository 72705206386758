import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, input, InputSignal, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorsService } from 'src/app/services/authors.service';
import { BehaviorSubject, catchError, combineLatest, combineLatestWith, distinctUntilChanged, filter, firstValueFrom, map, merge, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { toObservable } from "@angular/core/rxjs-interop";
import { BadgeConfig } from 'src/app/models/badge-config.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { BookSeries } from 'src/app/models/book-series.model';
import { ContentGridDataSource } from 'src/app/modules/shared/components/content-grid/content-grid.component';
import { BooksService } from 'src/app/services/books.service';
import { Book, BookReview } from 'src/app/models/book.model';
import { Author, AuthorLandingPageData, AuthorLead, FreeBookReaderMagnet } from 'src/app/models/author.model';
import { ColumnLayoutConfig } from 'src/app/models/column-layout-config.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PlatformService } from 'src/app/services/platform.service';
import { ChatWidgetService } from 'src/app/services/chatWidget.service';
import { VisibilityWatcherService } from 'src/app/services/visibility-watcher.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { CloudinaryService } from 'src/app/services/cloudinary.service';
import { environment } from 'src/environments/environment';
import { TitleService } from 'src/app/services/title.service';
import { Genre } from 'src/app/models/genre.model';
import { Trope } from 'src/app/models/trope.model';

@Component({
    selector: 'author-landing-page-container',
    templateUrl: './author-landing-page-container.component.html',
    styleUrl: './author-landing-page-container.component.scss',
    animations: [
        trigger(
            'stickyFooterAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({ "max-height": 0 }),
                        animate('1s ease-out', style({ "max-height": "*" }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ "max-height": "*" }),
                        animate('1s ease-in', style({ "max-height": 0 }))
                    ]
                )
            ]
        )
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorLandingPageContainerComponent implements OnInit, OnDestroy {

    authorSlug: InputSignal<string | undefined> = input();
    previewMode: boolean = false;

    constructor(
        private _route: ActivatedRoute,
        private _authorService: AuthorsService,
        private _booksService: BooksService,
        private _router: Router,
        private _chatService: ChatWidgetService,
        private _titleService: TitleService,
        private _cd: ChangeDetectorRef,
        private _platformService: PlatformService
    ) { }


    ngOnDestroy(): void {
        this._chatService.enable();
    }

    ngOnInit() {
        this._chatService.disable();
    }

    previewData$: Observable<AuthorLead> = this._route.queryParams.pipe(
        map(p => p.p),
        filter(p => !!p),
        distinctUntilChanged(),
        tap(_ => {
            this.previewMode = true;
        }),
        filter(_ => {
            return this._platformService.isBrowser()
        }),
        map(key => {
            return new Observable<AuthorLead>(observer => {
                window.addEventListener("message", event => {
                    if (event.data.key === key) {
                        observer.next(event.data.data);
                    }
                });
            })
        }),
        switchMap(obs => obs),
        distinctUntilChanged((prev, current) => {
            return JSON.stringify(prev) === JSON.stringify(current);
        }),
        tap(data => {
            if (data?.author) {
                this.previewMode = true;
                this._cd.markForCheck();
            }
            else {
                this._router.navigate(['/404']);
            }
        })
    );

    paramSlug$ = this._route.params.pipe(
        map(data => data.slug),
        filter(slug => !!slug),
        shareReplay(1)
    );

    inputSlug$ = toObservable(this.authorSlug).pipe(
        filter(slug => !!slug),
        shareReplay(1)
    )

    previewAuthor$: Observable<Author> = this.previewData$.pipe(
        map(data => data.author),
        tap(author => {
            this._titleService.setTitle(author.name);
        }),
        shareReplay(1)
    );
    realAuthor$: Observable<Author> = merge(this.paramSlug$, this.inputSlug$).pipe(
        filter(slug => !!slug),
        switchMap(slug => this._authorService.findOneBySlug(slug)),
        tap(author => {
            if ((!author || !author?.hasLandingPage) && !this.previewMode) {
                console.error(`Landing page does not exist`);
                this._router.navigate(['/404']);
            }
        }),
        filter((author): author is Author => !!author),
        tap(async (author) => {
            this._titleService.setTitle(author.name);
        }),
        shareReplay(1)
    ) as Observable<Author>;
    author$: Observable<Author> = merge(this.previewAuthor$, this.realAuthor$);


    previewFeaturedBook$: Observable<Book | null> = this.previewData$.pipe(
        filter(data => data?.author?.landingPageData?.readerMagnet?.mode == "free_book"),
        map(data => (data.author.landingPageData.readerMagnet as FreeBookReaderMagnet).freeBookForm.book),
        shareReplay(1)
    );

    realFeaturedBook$: Observable<Book | null> = this.author$.pipe(
        map((author: Author) => {
            let book;
            if (author.landingPageData?.readerMagnet?.mode === "free_book") {
                book = author.books?.find(b => b.id === (author.landingPageData.readerMagnet as FreeBookReaderMagnet).freeBookForm.book.id)
            }
            if (book) {
                book.authors = [{
                    ...author,
                    books: []
                }];
                return book;
            }
            return null;
        })
    );
    featuredBook$: Observable<Book | null> = merge(this.previewFeaturedBook$, this.realFeaturedBook$);

    previewFeaturedBooks$: Observable<Book[]> = this.previewData$.pipe(
        map(data => data.featuredBooks),
        shareReplay(1)
    );
    realFeaturedBooks$: Observable<Book[]> = this.author$.pipe(
        map((author: Author) => {
            if (author?.landingPageData?.featuredBooks?.length) {
                return author?.books.filter(b => author?.landingPageData?.featuredBooks.map(bk => bk.id).includes(b.id!));
            }
            return author?.books ?? [];
        })
    );
    featuredBooks$: Observable<Book[]> = merge(this.previewFeaturedBooks$, this.realFeaturedBooks$);

    previewFeaturedReviews$: Observable<BookReview[]> = this.previewData$.pipe(
        map(data => data.featuredReviews),
        shareReplay(1)
    );
    realFeaturedReviews$: Observable<BookReview[]> = this.author$.pipe(
        filter(_ => !this.previewMode),
        map(author => {
            if (author.landingPageData?.featuredReviews?.length) {
                return this._booksService.queryBookReviews(author.landingPageData.featuredReviews.map(r => r.id), true);
            }
            return this._authorService.getFeaturedReviews(author.id!);
        }),
        switchMap(reviews => reviews),
        shareReplay(1)
    );
    featuredReviews$: Observable<BookReview[]> = merge(this.previewFeaturedReviews$, this.realFeaturedReviews$);

    previewAuthorGenres$: Observable<Genre[]> = this.previewData$.pipe(
        map(data => data.genres)
    );
    realAuthorGenres$: Observable<Genre[]> = this.author$.pipe(
        filter(author => !!author),
        filter(_ => !this.previewMode),
        switchMap(author => this._authorService.getAuthorGenres(author!.id!)),
        shareReplay(1)
    );
    authorGenres$: Observable<Genre[]> = merge(this.previewAuthorGenres$, this.realAuthorGenres$);

    previewAuthorTropes$: Observable<Trope[]> = this.previewData$.pipe(
        map(data => data.tropes),
    );
    realAuthorTropes$: Observable<Trope[]> = this.author$.pipe(
        filter(author => !!author),
        filter(_ => !this.previewMode),
        switchMap(author => this._authorService.getAuthorTropes(author!.id!)),
        shareReplay(1)
    );
    authorTropes$: Observable<Trope[]> = merge(this.previewAuthorTropes$, this.realAuthorTropes$);

}
