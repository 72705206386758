<ng-container *ngIf="readables?.length">
    <div class="row c-pb-4">
        <h2 class="c-mb-0 worldmaker-section-title">{{header}}</h2>
    </div>
    <div class="row c-pb-16">
        <h3 class="c-mb-0 subheader">{{subheader}}</h3>
    </div>
    <div class="row">
        <div *ngFor="let readable of readables" class="col-12 col-md-6 col-lg-4">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex c-pb-16">
                        <img class="c-me-15 sample-img" *ngIf="readable.book?.images?.cover" [src]="readable.book!.images!.cover | cloudinaryScaleHeight:60 | cloudinaryQuality : 100 | cloudinaryFormat | cloudinaryUrl" alt="Book Cover">
                        <div class="d-flex flex-column">
                            <span class="book-title c-mb-8">{{readable.book!.title}}</span>
                            <span class="author-title d-flex align-items-center">
                                <img
                                    [src]="readable.author!.images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                                    alt="Author picture"
                                    class="circular-pfp c-me-5"
                                />
                                <span>{{ readable.author!.name }}</span>
                            </span>
                        </div>
                    </div>
                    <readables-reader-popup [readable]="readable" [floatingContent]="floatingCTA">
                        <button class="btn btn-dark btn-round btn-with-icon sample-btn">
                            <feather-icon icon="book" width="16" height="16"></feather-icon>
                            Read sample
                        </button>
                    </readables-reader-popup>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #floatingCTA>
    <div class="d-flex justify-content-center align-items-center">
        <button class="btn btn-primary btn-round" style="text-wrap: nowrap;" (click)="triggerHeaderPopup()">
            Read the full book free
        </button>
    </div>
</ng-template>