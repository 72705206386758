import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
    selector: "ecommerce-faq",
    templateUrl: "./ecommerce-faq.component.html",
    styleUrl: "./ecommerce-faq.component.scss",
    standalone: true,
    imports: [CommonModule, SharedModule]
})
export class EcommerceFAQ implements OnChanges {
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.faqs?.currentValue) {
            // console.log("FAQs changed", this.faqs);
            this.faqState = this.faqs.map(_ => false);
        }
    }

    @Input() header = "Frequently asked questions";
    @Input() faqs = [{
        title: "1. How does the free book delivery work?",
        body: "Every Saturday, we'll send you a link to download your free eBook. You'll have access to it throughout the weekend and beyond."
    }, {
        title: "2. Can I keep the books after the free period?",
        body: "Yes! Once you've downloaded a book, it's yours to keep and enjoy forever."
    }, {
        title: "3. What happens after the 4 free weekends?",
        body: "After your 4 free weekends, you can choose between bestselling paid and free books. No obligation!"
    }, {
        title: "4. How do I choose my preferred heat level?",
        body: "Each book comes with a \"spice rating.\" You can use this to select books that match your comfort level, from sweet to steamy."
    }, {
        title: "5. Can I read the books on my Kindle/e-reader/tablet/phone?",
        body: "Yes! Our eBooks are compatible with all major e-readers, tablets, and smartphones."
    }, {
        title: "6. Do I need to provide payment information to get the free books?",
        body: "No payment information is required at all!"
    }]

    faqState = this.faqs.map(_ => false);
}