import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { EmailSubscriptionService } from './services/email-subscriptions.service';
import { UnsubComponent } from './components/unsub/unsub.component';
import { RouterModule } from '@angular/router';



@NgModule({
    declarations: [
        UnsubComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild([{
            path: ":streamId",
            component: UnsubComponent
        }])
    ],
    providers: [EmailSubscriptionService]
})
export class SubscriptionsModule { }
