import { Component, Input } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
    selector: "ecommerce-explain-list",
    templateUrl: "./ecommerce-explain-list.component.html",
    styleUrl: "./ecommerce-explain-list.component.scss",
    standalone: true,
    imports: [CommonModule, SharedModule]
})
export class EcommerceExplainList {
    @Input() header = "Embark on a Νew Highland Romantic Journey each Weekend";

    @Input() data = [
        {
            "header": "Read 4+1 books for free",
            "description": "Every weekend, indulge in a new bestselling Scottish romance, delivered right to your email inbox."
        },
        {
            "header": "Curated Scottish novels",
            "description": "From misty glens to grand estates, lose yourself in tales of passion and intrigue."
        },
        {
            "header": "Prefer clean and sweet or super spicy? The choice is yours.",
            "description": "From sweet tension to passionate encounters, find your perfect heat level."
        },
        {
            "header": "Unlock bonus scenes and more exclusive content",
            "description": "Delve deeper with exclusive bonus scenes, character insights, and behind-the-scenes content."
        }
    ]


}