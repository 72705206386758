import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookPageModule } from './pages/book-page/book-page.module';
import { AuthGuard } from './modules/auth/auth.guard';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UnsubComponent } from './modules/subscriptions/components/unsub/unsub.component';
import { MagicAuthPageComponent } from './pages/magic-auth-page/magic-auth-page.component';
import { AuthorLandingPageContainerComponent } from './modules/books/components/authors/author-landing-page/author-landing-page-container/author-landing-page-container.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./pages/splash-home-page/splash-home-page.module').then(
                (m) => m.SplashHomePageModule
            ),
    },
    {
        path: 'explore',
        loadChildren: () =>
            import('./pages/home-page/home-page.module').then(
                (m) => m.HomePageModule
            ),
        title: 'Explore',
    },
    {
        path: 'authors',
        loadChildren: () =>
            import('./pages/author-page/author-page.module').then(
                (m) => m.AuthorPageModule
            ),
    },
    {
        path: 'genres',
        loadChildren: () =>
            import('./pages/genre-page/genre-page.module').then(
                (m) => m.GenrePageModule
            ),
    },
    {
        path: 'tropes',
        loadChildren: () =>
            import('./pages/trope-page/trope-page.module').then(
                (m) => m.TropePageModule
            ),
    },
    {
        path: "library",
        loadChildren: () =>
            import('./pages/library-page/library-page.module').then(
                (m) => m.LibraryPageModule
            ),
        // canActivate: [AuthGuard]
    },
    {
        path: '',
        loadChildren: () =>
            import('./pages/user-profile/user-profile.module').then(
                (m) => m.UserProfileModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'worlds',
        loadChildren: () =>
            import('./pages/world-page/world-page.module').then(
                (m) => m.WorldPageModule
            ),
    },
    {
        path: 'pages',
        loadChildren: () =>
            import('./pages/landing-page/landing-page.module').then(
                (m) => m.LandingPageModule
            ),
    },
    {
        path: "subscriptions",
        loadChildren: () => import('./modules/subscriptions/subscriptions.module').then(
            (m) => m.SubscriptionsModule
        ),
    },
    {
        path: "literature-quizzes",
        loadChildren: () =>
            import('./modules/literature-quizzes/literature-quizzes.module').then(
                (m) => m.LiteratureQuizzesModule
            )
    },
    {
        path: "book-crews/fiction-facts", // IF THIS URL CHANGES, CHANGE AppComponent.isTransparent
        loadChildren: () =>
            import('./pages/fiction-facts-page/fiction-facts.module').then(
                (m) => m.FictionFactsModule
            )
    },
    {
        path: "publishers",
        loadChildren: () => import('./modules/publishers/publishers.module').then(m => m.PublishersModule)
    },
    {
        path: "author-pages/:slug",
        component: AuthorLandingPageContainerComponent
    },
    {
        path: 'magic',
        component: MagicAuthPageComponent,
        data: {
            category: 'Magic Auth Page',
            trackPageView: false
        },
    },
    {
        path: '404',
        component: NotFoundComponent,
        data: {
            category: '404',
        },
    },
    // default route
    {
        path: '**',
        redirectTo: '/404',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            scrollPositionRestoration: 'enabled',
            anchorScrolling: "enabled"
        }),
        BookPageModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
