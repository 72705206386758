import { Directive, ElementRef, Input, Renderer2, OnChanges, SimpleChanges } from "@angular/core";

@Directive({
    selector: "[backgroundImage]"
})
export class BackgroundImageDirective implements OnChanges {
    @Input("backgroundImage") backgroundImageUrl!: string;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["backgroundImageUrl"]) {
            if (this.backgroundImageUrl) {
                this.renderer.setStyle(
                    this.el.nativeElement,
                    "backgroundImage",
                    `url(${this.backgroundImageUrl})`
                );
            }
            else {
                this.renderer.removeStyle(this.el.nativeElement, "backgroundImage");
            }
        }
    }
}
