import { Directive, ElementRef, Input, Renderer2, OnChanges, SimpleChanges } from "@angular/core";

@Directive({
    selector: "[backgroundColor]"
})
export class BackgroundColorDirective implements OnChanges {
    @Input("backgroundColor") backgroundColor!: string;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["backgroundColor"] && this.backgroundColor) {
            this.renderer.setStyle(
                this.el.nativeElement,
                "backgroundColor",
                this.backgroundColor
            );
        }
    }
}
