import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';

@Pipe({
    name: 'cloudinaryUrl',
    pure: true
})

export class CloudinaryUrlPipe implements PipeTransform {
    transform(value: CloudinaryImage): string {
        //@ts-ignore
        return value.publicID.includes("http") && !value.publicID.includes("cloudinary") ? value.publicID : value?.toURL().split("?")[0];
    }
}