import { Directive, ElementRef, Input, Renderer2, OnChanges, SimpleChanges } from "@angular/core";
import { PlatformService } from "src/app/services/platform.service";

@Directive({
    selector: "[backgroundVideo]"
})
export class BackgroundVideoDirective implements OnChanges {
    @Input("backgroundVideo") src!: string;


    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private platformService: PlatformService
    ) {
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["src"] && this.src) {
            if (this.platformService.isBrowser()) {
                this.el.nativeElement.querySelectorAll("video").forEach((existing: HTMLVideoElement) => {
                    this.renderer.removeChild(this.el.nativeElement, existing);
                });
            }
            const videoElement: HTMLVideoElement = this.renderer.createElement("video");
            this.renderer.setStyle(videoElement, "position", "absolute");
            this.renderer.setStyle(videoElement, "top", "0");
            this.renderer.setStyle(videoElement, "left", "0");
            this.renderer.setStyle(videoElement, "width", "100%");
            this.renderer.setStyle(videoElement, "height", "100%");
            this.renderer.setStyle(videoElement, "objectFit", "cover");
            this.renderer.setStyle(videoElement, "zIndex", "-1");
            // this.renderer.setAttribute(videoElement, "autoplay", "true");
            // this.renderer.setAttribute(videoElement, "muted", "true");
            // this.renderer.setAttribute(videoElement, "loop", "true");
            videoElement.autoplay = true;
            videoElement.muted = true;
            videoElement.loop = true;
            videoElement.playsInline = true;
            videoElement.controls = false;
            this.renderer.appendChild(this.el.nativeElement, videoElement);
            this.renderer.setAttribute(videoElement, "src", this.src);
        }
    }
}