<a *ngIf="linkEnabled && data.link; else noLink" class="d-flex no-decoration position-relative review-list-card" [href]="data.link" target="_blank">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</a>
<ng-template #noLink>
    <div class="position-relative d-flex review-list-card no-links">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #contentTemplate>
    <div class="w-100 d-flex justify-content-start">
        <star-rating [rating]="data.rating"></star-rating>
    </div>
    <h3 class="title c-mb-0" [title]="data.title">{{data.title | textLimit:35:'...'}}</h3>
    <div class="w-100 review-container">
        {{data.body}}
    </div>
    <div class="w-100 d-flex justify-content-start review-footer">
        <strong>{{data.name | textLimit: 20 : '...'}}</strong>
    </div>
    <div *ngIf="!singleBookMode && data.book?.title" class="w-100 d-flex justify-content-start book-name">
        Review for {{data.book.title}}
    </div>
</ng-template>


