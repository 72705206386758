import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, firstValueFrom, map, of, switchMap } from 'rxjs';
import { Book, BookReview, BookShortLink } from '../models/book.model';
import { BookSeries } from '../models/book-series.model';
import { environment } from 'src/environments/environment';
import { BookLaunch } from '../models/book-launch.model';
import { BookExtras } from '../models/book-extras.model';
import { PaginatedResponse } from '../models/paginated-response.model';
import { ArcContact } from '../models/arc-contact.model';

@Injectable({
    providedIn: 'root',
})
export class BooksService {
    private apiUrl = `${environment.baseUrl}/api/books`;

    constructor(private http: HttpClient) { }

    findAll(page: number = 1): Observable<Book[]> {
        return this.http.get<PaginatedResponse<Book>>(`${this.apiUrl}?page=${page}`).pipe(
            map(res => res.data)
        )
    }

    findOneById(id: number): Observable<Book> {
        return this.http.get<Book>(`${this.apiUrl}/id/${id}`);
    }

    searchByIds(ids: number[]): Observable<Book[]> {
        return this.http.post<Book[]>(`${this.apiUrl}/search`, { ids });
    }

    findOneBySlug(slug: string): Observable<Book> {
        return this.http.get<Book>(`${this.apiUrl}/slug/${slug}`);
    }

    findOneBookByTitle(title: string): Observable<Book> {
        const params = new HttpParams().set('title', title);
        return this.http.get<Book>(`${this.apiUrl}/title`, { params });
    }

    findOneBookByIsbn(isbn: string): Observable<Book> {
        const params = new HttpParams().set('isbn', isbn);
        return this.http.get<Book>(`${this.apiUrl}/isbn`, { params });
    }

    findBooksByAuthorName(authorName: string): Observable<Book[]> {
        const params = new HttpParams().set('authorName', authorName);
        return this.http.get<Book[]>(`${this.apiUrl}/authorName`, { params });
    }

    findBooksByAuthorId(authorId: number): Observable<Book[]> {
        const params = new HttpParams().set('authorId', authorId.toString());
        return this.http.get<Book[]>(`${this.apiUrl}/authorId`, { params });
    }

    findByAuthorIdOrderedByPublishDate(authorId: number): Observable<Book[]> {
        const params = new HttpParams().set('authorId', authorId.toString());
        return this.http.get<Book[]>(`${this.apiUrl}/authorId/publish-order`, { params });
    }

    findFreeBooks(page: number): Observable<Book[]> {
        const params = new HttpParams().set('page', page.toString());
        return this.http.get<Book[]>(`${this.apiUrl}/free`, { params });
    }

    findAllBookLaunches(): Observable<BookLaunch[]> {
        return this.http.get<PaginatedResponse<BookLaunch>>(`${this.apiUrl}/type/launch?page=1`).pipe(
            map(res => res.data)
        );
    }

    findAllPrelaunches(): Observable<PaginatedResponse<Book>> {
        return this.http.get<PaginatedResponse<Book>>(`${this.apiUrl}/type/prelaunch`);
    }

    findBookLaunchByBookId(bookId: number):Promise<BookLaunch> {
        return firstValueFrom(this.http.get<BookLaunch>(`${this.apiUrl}/launch/${bookId}`));
    }

    getFreeBook(email: string, bookId: number): Promise<any> {
        return firstValueFrom(this.http.post(`${this.apiUrl}/free`, {
            email,
            bookId
        }, {
            observe: 'response',
            responseType: 'blob' as 'json'
        }));
    }

    findOneSeriesById(id: number): Observable<BookSeries> {
        return this.http.get<BookSeries>(`${this.apiUrl}/series/id/${id}`);
    }

    findOneSeriesBySlug(slug: string): Observable<BookSeries> {
        return this.http.get<BookSeries>(`${this.apiUrl}/series/slug/${slug}`);
    }

    findAllSeriesByAuthorOrderedByPublishDate(authorId: number): Observable<BookSeries[]> {
        return this.http.get<BookSeries[]>(`${this.apiUrl}/series/authorId/${authorId}/publish-order`);
    }

    findBookReviews(bookId: number, limit: number = -1, includeHtml: boolean = false): Observable<BookReview[]> {
        return this.http.get<BookReview[]>(`${this.apiUrl}/${bookId}/reviews?limit=${limit}&includeHtml=${includeHtml ? 1 : 0}`);
    }

    queryBookReviews(options: { ids?: number[], amazonIds?: string[] }, includeHtml: boolean = false): Observable<BookReview[]> {
        return this.http.post<BookReview[]>(`${this.apiUrl}/reviews/query`, { ...options, includeHtml });
    }

    getShortLinksById(bookId: number): Observable<BookShortLink[]> {
        return this.http.get<BookShortLink[]>(`${this.apiUrl}/id/${bookId}/links/short`);
    }

    getLastRelease(): Observable<Book> {
        return this.http.get<Book>(`${this.apiUrl}/last-release`);
    }

    getBookRecommendations(): Observable<Book[]> {
        return this.http.get<Book[]>(`${this.apiUrl}/recommendations`);
    }

    rateBook(bookId: number, rating: number, email?: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/rating`, { rating, bookId, email });
    }

    getBookRating(bookId: number, email?: string): Observable<number> {
        return this.http.post<{rating: number}>(`${this.apiUrl}/${bookId}/rating`, {
            email
        }).pipe(
            map(res => res.rating)
        );
    }

    getBookExtras(bookSlug: string, type: "characters"): Observable<BookExtras> {
        return this.http.get<BookExtras>(`${this.apiUrl}/extras/${bookSlug}/${type}`);
    }

    getBookReviewById(reviewId: number): Observable<BookReview> {
        return this.http.get<BookReview>(`${this.apiUrl}/reviews/${reviewId}`);
    }
    
    getAvailableArcs(email?: string) {
        return this.http.post<{book: Book, slots: number}[]>(`${this.apiUrl}/arc/available`, {email});
    }

    getJoinedArcs(email?: string) {
        return this.http.post<Book[]>(`${this.apiUrl}/arc/joined`, {email});
    }

    joinArc(bookSlug: string, email?: string) {
        return this.http.post<void>(`${this.apiUrl}/arc/${bookSlug}/join`, {email})
    }

    updateArcReadingProgress(bookSlug: string, reading: ArcContact["reading"], email?: string) {
        return this.http.put<void>(`${this.apiUrl}/arc/${bookSlug}/progress`, {email, reading})
    }

    getArcReadingProgress(bookSlug: string, email?: string) {
        return this.http.post<ArcContact["reading"]>(`${this.apiUrl}/arc/${bookSlug}/progress`, {email})
    }
}
