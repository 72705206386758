import { Inject, Injectable, Injector } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Author } from '../models/author.model';
import { Book } from '../models/book.model';
import { ɵgetDOM } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class PlatformService {

    protected readonly _baseUrl: string = environment.baseUrl;

    isMobile: Observable<boolean> = new Observable<boolean>()
    screenSize: Observable<"xs" | "sm" | "md" | "lg" | "xl" | "xxl"> = new Observable();
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private _meta: Meta,
        private _router: Router
    ) {
        if (this.isBrowser()) {
            this.isMobile = fromEvent(window, 'resize')
                .pipe(
                    startWith(0),
                    map(() => window.innerWidth < 768),
                    distinctUntilChanged()
                );
            this.screenSize = fromEvent(window, 'resize')
                .pipe(
                    startWith(0),
                    map(() => {
                        if (window.innerWidth < 576) {
                            return "xs";
                        } else if (window.innerWidth < 768) {
                            return "sm";
                        } else if (window.innerWidth < 992) {
                            return "md";
                        } else if (window.innerWidth < 1200) {
                            return "lg";
                        } else if (window.innerWidth < 1400) {
                            return "xl";
                        } else {
                            return "xxl";
                        }
                    }),
                    distinctUntilChanged()
                );
        }
    }


    _getPlatformId(): object {
        return this.platformId;
        // return this._injector.get(PLATFORM_ID);
    }

    // private _getPlatformLocation(): PlatformLocation {
    // return this._injector.get(PlatformLocation);
    // }

    isBrowser(): boolean {
        return isPlatformBrowser(this._getPlatformId());
    }

    isServer(): boolean {
        return !isPlatformBrowser(this._getPlatformId());
    }

    getLocation(): Location | undefined {
        return typeof window !== 'undefined' ? window.location : undefined;
    }

    get currentUrl(): string {
        return environment.baseUrl +  this._router.url;
    }

    get pageUrls() {
        return {
            authors: () => this._baseUrl + "/authors",
            author: (author: Author) => this._baseUrl + "/" + author.slug,
            book: (author: Author, bookSlug: string) => this._baseUrl + "/" + author.slug + "/" + bookSlug
        }
    }

    goHome() {
        this._router.navigate(['']);
    }

    getHomeUrl(): string | undefined {
        if (this.isServer()) {
            return undefined;
        }
        let origin = window.location.origin;
        if (origin == "localhost") {
            origin = "http://localhost:4200";
        }
        return window.location.origin + "/explore";
    }

    goTo404() {
        // Use this to trigger the Cloudflare worker
        if (this.isBrowser()) {
            window.location.href = this._baseUrl + "/404";
        }
        else {
            this._router.navigate(['404']);
        }
    }

    reload() {
        if (this.isBrowser()) {
            window.location.reload();
        }
    }

    // goToBookPage(book: Book) {
    //     this._router.navigate(["book", book.slug]);
    // }

    platformLog(...args: any[]) {
        if (this.isServer()) {
            console.log("Server log", ...args);
        }
        else {
            console.log("Client log", ...args);
        }
    }

    get metaTags() {
        return {
            setTitle: this.setMetaTitle.bind(this),
            removeTitle: this.removeMetaTitle.bind(this),
            setDescription: this.setMetaDescription.bind(this),
            removeDescription: this.removeMetaDescription.bind(this),
            setKeywords: this.setMetaKeywords.bind(this),
            removeKeywords: this.removeMetaKeywords.bind(this),
            setDefaultRobots: this.setDefaultMetaRobots.bind(this),
            removeMetaRobots: this.removeMetaRobots.bind(this),
            setNoIndex: this.setNoIndexMetaRobots.bind(this),
            setOpenGraphImage: this.setOpenGraphImage.bind(this),
            removeOpenGraphImage: this.removeOpenGraphImage.bind(this),
            setDefaultOpenGraphImage: this.setDefaultOpenGraphImage.bind(this),
            getCfHref: this.getCfHref.bind(this)
        }
    }

    private getCfHref() {
        if (this.isServer()) {
            return undefined;
        }
        return document.querySelector("meta[name='cf-href']")?.getAttribute("content") || "";
    }

    private setMetaTitle(title: string) {
        this.removeMetaTitle();
        title += " • Crewfiction";
        this._meta.addTag({ name: 'title', content: title });
        this._meta.addTag({ property: "og:title", content: title });
        this._meta.addTag({ name: "twitter:title", content: title });
    }

    private removeMetaTitle() {
        this._meta.removeTag("name='title'");
        this._meta.removeTag("property='og:title'");
        this._meta.removeTag("name='twitter:title'");
    }

    private setMetaDescription(description: string) {
        let textDescription: string;
        if (description.includes("<p")) {
            const dom = ɵgetDOM();
            const div = dom.createElement("div");
            div.innerHTML = description;
            let text = "";
            for (const el of Array.from(div.children)) {
                //@ts-ignore
                text += (el.innerText + " ");
            }
            textDescription = text.trim();
        } else {
            textDescription = description;
        }
        textDescription = textDescription.replace(/\s\s+/g, ' ');
        this.removeMetaDescription();
        this._meta.addTag({ name: 'description', content: textDescription });
        this._meta.addTag({ property: "og:description", content: textDescription });
        this._meta.addTag({ name: "twitter:description", content: textDescription });
    }

    private removeMetaDescription() {
        this._meta.removeTag("name='description'");
        this._meta.removeTag("property='og:description'");
        this._meta.removeTag("name='twitter:description'");
    }

    private setOpenGraphImage(imageUrl: string) {
        this.removeOpenGraphImage();
        this._meta.addTag({ property: "og:image", content: imageUrl });
        this._meta.addTag({ name: "twitter:card", content: "summary_large_image" });
        this._meta.addTag({ name: "twitter:image", content: imageUrl });
    }

    private removeOpenGraphImage() {
        this._meta.removeTag("property='og:image'");
        this._meta.removeTag("name='twitter:card'");
        this._meta.removeTag("name='twitter:image'");
    }

    private setDefaultOpenGraphImage() {
        this.removeOpenGraphImage();
        this._meta.addTag({ property: "og:image", content: environment.openGraph.images.default });
        this._meta.addTag({ name: "twitter:card", content: "summary_large_image" });
        this._meta.addTag({ name: "twitter:image", content: environment.openGraph.images.default });
    }

    private setMetaKeywords(keywords: string | string[]) {
        this.removeMetaKeywords();
        if (Array.isArray(keywords)) {
            keywords = keywords.join(", ");
        }
        this._meta.addTag({ name: 'keywords', content: keywords })
    }

    private removeMetaKeywords() {
        this._meta.removeTag("name='keywords'");
    }

    private setDefaultMetaRobots() {
        this.removeMetaRobots();
        this._meta.addTag({ name: 'robots', content: 'index, follow' });
    }

    private setNoIndexMetaRobots() {
        this.removeMetaRobots();
        this._meta.addTag({ name: 'robots', content: 'noindex' });
    }

    private removeMetaRobots() {
        this._meta.removeTag("name='robots'");
    }
}
