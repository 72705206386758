import { NgModule, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { customAuthInterceptor } from 'src/app/services/custom-auth.interceptor';
import { BookCoverComponent } from './components/book-cover/book-cover.component';
import { CompactBookListingComponent } from './components/compact-book-listing/compact-book-listing.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ExpandableDescriptionComponent } from '../books/components/books/expandable-description/expandable-description.component';
import { RouterModule } from '@angular/router';
import { FeatherIconComponent } from './components/feather-icon/feather-icon.component';
import { BookUrlPipe } from './pipes/book-url.pipe';
import { WorldUrlPipe } from './pipes/world-url.pipe';
import { AuthorUrlPipe } from './pipes/author-url.pipe';
import { MainAuthorUrlPipe } from './pipes/main-author-url.pipe';
import { CloudinaryQualityPipe } from './pipes/cloudinary/cloudinary-quality.pipe';
import { CloudinaryScalePipe } from './pipes/cloudinary/cloudinary-scale.pipe';
import { CloudinaryScaleWidthPipe } from './pipes/cloudinary/cloudinary-scale-width.pipe';
import { CloudinaryScaleHeightPipe } from './pipes/cloudinary/cloudinary-scale-height.pipe';
import { CloudinaryCropPipe } from './pipes/cloudinary/cloudinary-crop.pipe';
import { CloudinaryCropWidthPipe } from './pipes/cloudinary/cloudinary-crop-width.pipe';
import { CloudinaryCropHeightPipe } from './pipes/cloudinary/cloudinary-crop-height.pipe';
import { CloudinaryUrlPipe } from './pipes/cloudinary/cloudinary-url.pipe';
import { cloudinaryThumb } from './pipes/cloudinary/cloudinary-video-thumb.pipe';
import { cloudinaryAnimatedLoop } from './pipes/cloudinary/cloudinary-animated-loop.pipe';
import { CloudinaryReadableColorPipe } from './pipes/cloudinary/cloudinary-readable-color.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { ProfilePictureUrlPipe } from './pipes/profile-picture.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ContentCardComponent } from './components/content-card/content-card.component';
import { BookRouterLinkPipe } from './pipes/book-router-link.pipe';
import { MainAuthorRouterLink } from './pipes/main-author-router-link.pipe';
import { GenreRouterLinkPipe } from './pipes/genre-router-link.pipe';
import { SlugPipe } from './pipes/slug.pipe';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { CloudinaryPublicIdPipe } from './pipes/cloudinary/cloudinary-public-id.pipe';
import { CloudinaryPrimaryColorPipe } from './pipes/cloudinary/cloudinary-primary-color.pipe';
import { TitleCardComponent } from './components/title-card/title-card.component';
import { ContentGridComponent } from './components/content-grid/content-grid.component';
import { TropeRouterLinkPipe } from './pipes/trope-router-link.pipe';
import { HorizontalScrollTickerComponent } from './components/horizontal-scroll-ticker/horizontal-scroll-ticker.component';
import { BookTypeGridComponent } from './components/book-type-grid/book-type-grid.component';
import { PidToProfilePicturePipe } from './pipes/pid-to-profile-picture.pipe';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { JoinCrewFormComponent } from './components/join-crew-form/join-crew-form.component';
import { JumbotronCarouselComponent } from './components/jumbotron-carousel/jumbotron-carousel.component';
import { BadgeListComponent } from './components/badge-list/badge-list.component';
import { PillTypePipe } from './pipes/pill-type.pipe';
import { TextLimitPipe } from './pipes/text-limit.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { ReviewsListComponent } from './components/reviews-list/reviews-list.component';
import { ReviewsListItemComponent } from './components/reviews-list/reviews-list-item/reviews-list-item.component';
import { AmazonLinkPipe } from './pipes/amazon-link.pipe';
import { AnchorHeaderComponent } from './components/anchor-header/anchor-header.component';
import { EmojiRainComponent } from './emoji-rain/emoji-rain.component';
import { SocialShareComponent } from './components/social-share/social-share.component';
import { CloudinaryFormatPipe } from './pipes/cloudinary/cloudinary-format.pipe';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { TransparentizePipe } from './pipes/transparentize.pipe';
import { PreloadImageDirective } from './directives/preload-image.directive';
import { ChatWidgetFacadeComponent } from './components/intercom-facade/chat-widget-facade.component';
import { PushNotificationTriggerComponent } from './components/push-notification-trigger/push-notification-trigger.component';
import { SpicyMeterComponent } from './components/spicy-meter/spicy-meter.component';
import { AddStarRatingComponent } from './add-star-rating/add-star-rating.component';
import { BackgroundImageDirective } from './directives/background-image.directive';
import { FomoCountdown } from './fomo-countdown/fomo-countdown.component';
import { FreeBookFormComponent } from './free-book-form/free-book-form.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { BackgroundColorDirective } from './directives/background-color.directive';
import { BackgroundVideoDirective } from './directives/background-video.directive';

const COMPONENTS = [
    BookCoverComponent,
    CompactBookListingComponent,
    HeaderComponent,
    LoadingComponent,
    FeatherIconComponent,
    BreadcrumbsComponent,
    ContentCardComponent,
    JumbotronComponent,
    TitleCardComponent,
    ContentGridComponent,
    HorizontalScrollTickerComponent,
    BookTypeGridComponent,
    JoinCrewFormComponent,
    ModalContainerComponent,
    JumbotronCarouselComponent,
    BadgeListComponent,
    ReviewsListComponent,
    ReviewsListItemComponent,
    AnchorHeaderComponent,
    SearchBarComponent,
    StarRatingComponent,
    ChatWidgetFacadeComponent,
    PushNotificationTriggerComponent,
    SpicyMeterComponent,
    AddStarRatingComponent,
    FomoCountdown,
    FreeBookFormComponent,
    // ImagePreviewComponent // uncomment if pipes fix does not work
];

const DIRECTIVES = [
    PreloadImageDirective,
    BackgroundImageDirective,
    BackgroundColorDirective,
    BackgroundVideoDirective
]

const PIPES = [
    BookUrlPipe,
    WorldUrlPipe,
    BookRouterLinkPipe,
    CloudinaryQualityPipe,
    CloudinaryScalePipe,
    CloudinaryScaleWidthPipe,
    CloudinaryScaleHeightPipe,
    CloudinaryCropPipe,
    CloudinaryCropWidthPipe,
    CloudinaryCropHeightPipe,
    CloudinaryUrlPipe,
    CloudinaryPublicIdPipe,
    CloudinaryPrimaryColorPipe,
    CloudinaryReadableColorPipe,
    cloudinaryThumb,
    cloudinaryAnimatedLoop,
    CloudinaryFormatPipe,
    TimeAgoPipe,
    ProfilePictureUrlPipe,
    AuthorUrlPipe,
    MainAuthorUrlPipe,
    MainAuthorRouterLink,
    GenreRouterLinkPipe,
    TropeRouterLinkPipe,
    SlugPipe,
    PidToProfilePicturePipe,
    PillTypePipe,
    TextLimitPipe,
    StripHtmlPipe,
    AmazonLinkPipe,
    TransparentizePipe
]

@NgModule({
    declarations: [
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ],
    exports: [
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES
    ],
    providers: [
        provideHttpClient(
            withFetch(),
            withInterceptors([customAuthInterceptor])
        )
    ]
})
export class SharedModule { }
