<ng-container *ngIf="book">
    <book-header-container
        [book]="book"
        [headerText]="data.headerText"
        [enableBreadcrumbs]="data.enableBreadcrumbs"
        [enableRating]="data.showRating"
        [showSpicyMeter]="data.showSpicyMeter"
        [content]="content"
        [altCoverIndex]="data.altCoverIndex"
        [showHeader]="data.showHeader">
    </book-header-container>

    <ng-template #content>
        <div class="book-header-cta-controls">
            @defer (when authChecked$ | async) {
                <ng-container *ngIf="isLoggedIn === false && !hasFreeBook">
                    <form [formGroup]="emailForm"
                      (ngSubmit)="readNow()">
                        <div class="input-container">
                            <input type="email" required class="form-control rounded-input dark-input" formControlName="email" placeholder="Enter your email" (keyup)="onEmailChanged()">
                        </div>
                        <div class="button-container">
                            <button type="submit"
                                [disabled]="loading"
                                class="btn btn-dark btn-round">
                                <span class="spinner-border spinner-border-sm c-me-8" role="status" aria-hidden="true" *ngIf="loading"></span>
                                {{ data.ctaText }}
                            </button>
                        </div>
                        <div class="form-errors">
                            <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger" [@errorMessageAnimation]>
                                <small>
                                    {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                                </small>
                            </div>
                            <div *ngIf="emailSuggestionError" class="text-danger" [@errorMessageAnimation]>
                                <small>
                                    Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                                </small>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="isLoggedIn && !hasFreeBook">
                    <div class="button-container">
                        <button type="button"
                            [disabled]="loading"
                            (click)="readNow()"
                            class="btn btn-dark btn-round">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                            {{ data.ctaText }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="hasFreeBook">
                    <div class="button-container">
                        <readables-reader-popup-v2 [readable]="readable" *ngIf="readable">
                            <button class="btn btn-primary btn-round btn-with-icon">
                                <feather-icon icon="book"></feather-icon>
                                Start Reading
                            </button>
                        </readables-reader-popup-v2>
                    </div>
                    <div class="button-container">
                        <download-readable [readable]="readable" [showInstructionsTrigger]="true" *ngIf="readable" buttonStyle="big_dark"></download-readable>
                    </div>
                </ng-container>
            } @placeholder (minimum 500ms) {
                
            }
            <!-- <ng-container *ngIf="isLoggedIn && hasFreeBook">
                <div class="button-container">
                    <button class="btn btn-round btn-dark btn-with-icon" *ngIf="!followsAuthor" (click)="followAuthor()">
                        <feather-icon icon="user-plus"></feather-icon>
                        Follow Author
                    </button>
                    <button class="btn btn-round btn-dark btn-with-icon" *ngIf="followsAuthor" (click)="unfollowAuthor()">
                        <feather-icon icon="user-check"></feather-icon>
                        Following
                    </button>
                </div>
            </ng-container> -->
        </div>
    </ng-template>

    <div class="card sticky-container container-xxl" [@stickyFooterAnimation] *ngIf="data.enableStickyFooter && scrolled && isLoggedIn === false && !hasFreeBook">
        <div class="card-body c-py-20 c-px-0">
            <ng-container *ngIf="isMobile | async">
                <div class="row text-center">
                    <div class="col-12">
                        <span class="text-reset d-flex justify-content-center align-items-center">
                            <img
                                [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                                alt="Author picture"
                                class="circular-pfp c-me-10"
                            />
                            Free Book from&nbsp;<span class="author-name">{{ book.mainAuthorName }}</span>
                        </span>
                        <h6 class="book-title c-my-15">{{book.title}}</h6>
                    </div>
                    <form [formGroup]="emailForm"
                        (ngSubmit)="readNow()">
                        <div class="col-12 c-pb-12">
                            <input type="email" required class="form-control rounded-input dark-input" formControlName="email" placeholder="Enter your email" (keyup)="onEmailChanged()" (click)="onStickyInputClicked($event)">
                        </div>
                        <div class="col-12">
                            <button type="submit"
                                [disabled]="loading"
                                class="btn btn-dark btn-round w-100">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                                {{ data.ctaText }}
                            </button>
                        </div>
                        <div class="col-12 form-errors c-pt-12">
                            <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger" [@errorMessageAnimation]>
                                <small>
                                    {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                                </small>
                            </div>
                            <div *ngIf="emailSuggestionError" class="text-danger" [@errorMessageAnimation]>
                                <small>
                                    Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                                </small>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-container>
            <ng-container *ngIf="!(isMobile | async)">
                <div class="d-flex justify-content-between c-px-20">
                    <div class="pe-3 d-flex flex-column justify-content-center">
                        <img class="book-cover__image" [src]="coverSrc | cloudinaryFormat | cloudinaryScaleHeight : 50 | cloudinaryQuality : 100 | cloudinaryUrl" alt="book cover image" decoding="async" #imgRef />
                    </div>
                    <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <h6 class="book-title c-mb-10">{{book.title}}</h6>
                        <span class="text-reset d-flex justify-content-start align-items-center">
                            <img
                                [src]="book.authors[0].images.profile | cloudinaryFormat | cloudinaryScaleHeight : 22 | cloudinaryQuality : 100 | cloudinaryUrl"
                                alt="Author picture"
                                class="circular-pfp c-me-10"
                            />
                            Free Book from&nbsp;<span class="author-name">{{ book.mainAuthorName }}</span>
                        </span>
                    </div>
                    <form [formGroup]="emailForm"
                        (ngSubmit)="readNow()"
                        class="d-flex flex-wrap justify-content-end">
                        <div class="c-pe-12">
                            <input type="email" required class="form-control rounded-input dark-input mb-3 mb-md-0" formControlName="email" placeholder="Enter your email" (keyup)="onEmailChanged()" (click)="onStickyInputClicked($event)">
                        </div>
                        <div>
                            <button type="submit"
                                [disabled]="loading"
                                class="btn btn-dark btn-round w-fit">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                                {{ data.ctaText }}
                            </button>
                        </div>
                        <div class="flex-break"></div>
                        <div class="form-errors">
                            <div *ngIf="emailFormControl.dirty && emailFormControl.invalid" class="text-danger c-pt-12" [@errorMessageAnimation]>
                                <small>
                                    {{emailErrors && emailErrors.length ? emailErrors[0] : ''}}
                                </small>
                            </div>
                            <div *ngIf="emailSuggestionError" class="text-danger c-pt-12" [@errorMessageAnimation]>
                                <small>
                                    Did you mean <a class="email-correction" (click)="correctEmail()">{{emailSuggestionError}}</a> ?
                                </small>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
