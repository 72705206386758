import { Component, Input, OnInit} from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { CommonModule } from '@angular/common';
import { ReadablesModule } from 'src/app/modules/readables/readables.module';
import { ReadablesService } from 'src/app/modules/readables/services/readables.service';
import { Readable } from 'src/app/models/readable.model';
import { firstValueFrom } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: "ecommerce-read-sample",
    templateUrl: "./ecommerce-read-sample.component.html",
    styleUrl: "./ecommerce-read-sample.component.scss",
    standalone: true,
    imports: [CommonModule, SharedModule, ReadablesModule]
})
export class EcommerceReadSample implements OnInit {
    @Input() readableSlug = "married-to-a-heartless-laird-sneak-peek";
    @Input() readableSlugs = [];
    @Input() header = "Sneak Peek";
    @Input() subheader = "Dive into one of the free enthralling stories awaiting you!";

    constructor(
        private readonly _readablesService: ReadablesService,
        private readonly _modalService: NgbModal
    ) {}

    readables: Readable[] = [];

    async ngOnInit() {
        if(this.readableSlugs?.length > 0) {
            this.readables = await Promise.all(this.readableSlugs.map(slug => firstValueFrom(this._readablesService.info(slug))));
        }
        else {
            this.readables = [await firstValueFrom(this._readablesService.info(this.readableSlug))];
        }
    }

    async triggerHeaderPopup() {
        //we have to dismiss the reader
        //and then trigger the subscribe modal of the header component
        //so i just hack it like this:
        this._modalService.dismissAll();
        await new Promise((resolve => setTimeout(resolve, 500)));
        const trigger = document.querySelector<HTMLButtonElement>(".subscribe-trigger");
        if (trigger) {
            trigger.click();
        }
    }
}